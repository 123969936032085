import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/Preloader.css';


import Preloader from './components/Preloader';

const AdminBaseComponent = lazy(() => import('./AdminBaseComponent'))
const UserBaseComponent = lazy(() => import('./UserBaseComponent'))

const App = () => {

  return (
    <div>
      <BrowserRouter basename="/">

        <Suspense fallback={Preloader()}>
          <Routes>
            <Route path="/admin/*" element={<AdminBaseComponent />} />
            <Route path="*" element={<UserBaseComponent />} />
          </Routes>
        </Suspense>

      </BrowserRouter>
    </div>
  );
}

export default App;
